import styled from 'styled-components'

const SectionOverlay = styled.div`
  width: 100%;
  height: 100%;
  background: -moz-linear-gradient(
    -45deg,
    rgba(27, 77, 136, 1) 0%,
    rgba(27, 77, 136, 0.85) 50%,
    rgba(125, 185, 232, 0) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    -45deg,
    rgba(27, 77, 136, 1) 0%,
    rgba(27, 77, 136, 0.85) 50%,
    rgba(125, 185, 232, 0) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    135deg,
    rgba(27, 77, 136, 1) 0%,
    rgba(27, 77, 136, 0.85) 50%,
    rgba(125, 185, 232, 0) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1b4d88', endColorstr='#007db9e8',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  color: white;
`

export default SectionOverlay
