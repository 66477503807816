import React from 'react'
import SEO from '../../components/seo'
import SubPageLayout from '../../layouts/subPageLayout'
import bgPhoto from '../../images/bilxtra_kortet.png'
import beregnkostnadQRCode from '../../images/beregnkostnad.png'
import deleoppbetalingQRCode from '../../images/deleoppbetaling.png'
import styled from 'styled-components'
import { Section } from '../../components/styled/section'
import { Column1, Column2, Grid } from '../../components/styled/grid'

import SectionOverlay from '../../components/styled/section-overlay'
import { Link } from 'gatsby'

import '../style.css'

const Img = styled.img`
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
`
const HolderBoxSlector = styled.div`
  margin-top: -2rem;
`
const HolderMainContetent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 1024px) {
    width: 100%;
    grid-template-columns: 1fr;
  }
`
const Paragraph = styled.div`
  padding: 0 15px;
  font-size: 14px;
  vertical-align: top;
`

const OrderedList = styled.ol`
  padding: 0 30px;
  margin: 0;
  font-size: 14px;
`

//adding a samll re-runn to be removed after done
const BilXtraKonto = () => {
  const title = 'BilXtra Konto'
  const Component = () => {
    return (
      <>
        <p class="subTitle">Søk om Bilxtra konto på SMS, send kodeord "bilxtra" til 27700.</p>
        <div class="flex-container">
          <div class="flex-item-left">
            BilXtrakortet gir deg som forbruker betalingsutsettelse helt uten ekstra kostnad. Velger
            du å dele opp betalingen fra 4-12 måneder, kommer det ingen renter i tillegg. Kan brukes
            i både butikk og verksted!
            <p>
              Du vil umiddelbart få beskjed om kreditten er innvilget eller ikke. Ønsker du å
              benytte kontoen før du mottar kortet, ta med deg SMS svaret med kontonummer til
              verksted slik at du kan gjøre en manuell transaksjon på verkstedets terminal.
            </p>
          </div>
          <div class="flex-item-right">
            Slik fungerer det:
            <br />
            - 30-60 dager til første faktura
            <br />
            - Opp til 12 md. uten nominell rente
            <br />
            - Kredittgrense 40000 kroner
            <br />
            <p>
              Del opp kjøpet ditt til en månedskostnad som passer deg. Du betaler først når første
              faktura kommer, måneden etter kjøpet.
            </p>
          </div>
        </div>
      </>
    )
  }

  return (
    <SubPageLayout title={title} component={<Component />} style={{ padding: '0', margin: '0' }}>
      <SEO title={title} />
      <HolderBoxSlector>
        <SectionOverlay>
          <Section>
            <Grid>
              <HolderMainContetent>
                <Column1>
                  <Grid className="barcodeImages">
                    <Column1>
                      Beregn din månedlige kostnad
                      <Grid style={{ alignItems: 'flex-start' }}>
                        <Column1>
                          <Img width={140} src={beregnkostnadQRCode} />
                        </Column1>
                        <Column2>
                          <OrderedList>
                            <li>Skann QR-koden</li>
                            <li>Skriv inn kjøpsbeløpet</li>
                            <li>Se din månedlige kostnad</li>
                          </OrderedList>
                          <p style={{ padding: '0 15px', fontSize: '14px' }}>
                            <Link
                              to="https://priceinfo.resurs.com/prisskyltning.html?countryCode=NO&authorizedBankproductId=BK115069&storeId=44000&representativeId=44000&creditAmount=0"
                              style={{ textDecoration: 'none', color: '#fff' }}
                            >
                              Eller klikk på denne linken
                            </Link>
                          </p>
                        </Column2>
                      </Grid>
                    </Column1>
                    <Column1>
                      Vil du dele opp betalingen din?
                      <Grid style={{ alignItems: 'flex-start' }}>
                        <Column1>
                          <Img width={140} src={deleoppbetalingQRCode} />
                        </Column1>
                        <Column2>
                          <Paragraph>
                            Skann QR-koden og søk, få svar i løpet av få minutter.
                            <p style={{ fontSize: '14px' }}>
                              <Link
                                style={{ textDecoration: 'none', color: '#fff' }}
                                to="https://apponline.resurs.com/form/44000"
                              >
                                {' '}
                                Eller klikk på denne linken
                              </Link>
                            </p>
                          </Paragraph>
                        </Column2>
                      </Grid>
                    </Column1>
                  </Grid>
                </Column1>
                <Column2>
                  <Img src={bgPhoto} />
                </Column2>
              </HolderMainContetent>
            </Grid>
          </Section>
        </SectionOverlay>
      </HolderBoxSlector>
    </SubPageLayout>
  )
}

export default BilXtraKonto
